import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DtCustomIconsService} from '@ui/services/dt-custom-icons.service';
import {DtScriptsService} from '@ui/services/dt-scripts.service';
import {DtUserpilotService} from '@ui/services/dt-userpilot.service';
import {DtUtmService} from '@ui/services/dt-utm.service';
import {DtTranslateService} from '@ui/translation/services/dt-translate.service';
import {catchError, filter, fromEvent, map, of, Subject, take, takeUntil} from 'rxjs';

import {environment} from '../environments/environment';
import {DevToolsService} from './core/services/dev-tools.service';
import {NotificationsService} from './core/services/notifications.service';
import {ScreenService} from './core/services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private supportedLanguages = ['en', 'uk'];

  title: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private dtCustomIconsService: DtCustomIconsService,
    private dtTranslateService: DtTranslateService,
    private dtUserpilotService: DtUserpilotService,
    private dtScriptsService: DtScriptsService,
    private dtUtmService: DtUtmService,
    private devToolsService: DevToolsService,
    private screenService: ScreenService,
    private notificationsService: NotificationsService
  ) {
    this.dtTranslateService.initTranslations(this.supportedLanguages);
  }

  ngOnInit(): void {
    this.initIcons();
    this.initScreenService();
    this.initUserpilot();
    this.loadCookieScript();
    this.handleUtmParams();
    this.handleDevToolsConfigUpdates();
    this.handleConnection();
    this.handleVisibilityChange();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initIcons(): void {
    this.dtCustomIconsService.registerCustomIcons();
  }

  private initScreenService(): void {
    this.screenService.init();
  }

  private setDefaultLanguage(): void {
    this.dtTranslateService.setDefaultLang('en');
  }

  private initUserpilot(): void {
    this.dtUserpilotService.init(environment.USER_PILOT_KEY, environment.production);
    this.handleUserpilotReload();
  }

  private handleUserpilotReload(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.dtUserpilotService.reload();
      });
  }

  private loadCookieScript(): void {
    const url = `//cdn.cookie-script.com/s/${environment.COOKIE_SCRIPT_ID}.js`;
    this.dtScriptsService.loadScript({id: 'cookie-script-concent', src: url}).subscribe();
  }

  private loadDriftlyScript(): void {
    const script = document.createElement('script');
    script.setAttribute('driftly-api', '6jYDILFiWVMAiPWMRvwpm2QxykLm4QoCLa9OorkVHnITy');
    script.src = 'https://storage.googleapis.com/driftly-cdn/driftly-loader.umd.js';
    document.head.appendChild(script);
  }

  private handleUtmParams(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.dtUtmService.setUtmList(this.activatedRoute.snapshot.queryParams);
      });
  }

  private handleDevToolsConfigUpdates(): void {
    this.devToolsService.getDevToolsConfigChanges().subscribe((config) => {
      this.dtTranslateService.setDebugMode(config.translationsDisabled);
      this.dtTranslateService.setEditMode(config.translationsEditable);
    });
  }

  private handleConnection(): void {
    fromEvent(window, 'online')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notificationsService.createInfoNotification(`Connection restored`);
      });
    fromEvent(window, 'offline')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notificationsService.createWarningNotification(`Connection lost`);
      });
  }

  private handleVisibilityChange(): void {
    fromEvent(document, 'visibilitychange')
      .pipe(
        map(() => document.visibilityState === 'visible'),
        takeUntil(this.destroyed$)
      )
      .subscribe((isVisible) => {
        if (isVisible) {
          this.checkAppVersion();
        }
      });
  }

  private checkAppVersion(): void {
    const url = window.location.href;
    this.http
      .get(url, {
        responseType: 'text',
        params: {
          ignoreErrorWarning: true,
          ignoreSessionRedirect: true
        }
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((html) => {
        if (html) {
          const serverDocument = new DOMParser().parseFromString(html, 'text/html');
          const serverVersion = serverDocument.querySelector('meta[property="version"]')?.getAttribute('content');
          const currentVersion = document.querySelector('meta[property="version"]')?.getAttribute('content');
          console.log(serverVersion, currentVersion);
          if (serverVersion !== currentVersion) {
            this.notificationsService.createInfoNotification('Please reload your page', 'New version');
          }
        }
      });
  }
}
