import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DtProfileShort} from '@ui/models/dt-profile.model';
import {DtMediaContentService} from '@ui/services/dt-media-content.service';
import {map, MonoTypeOperatorFunction, Observable, of, pipe, switchMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtProfilesService {
  constructor(
    @Inject('environment') private environment: {API_URL: string},
    private http: HttpClient,
    private mediaContentService: DtMediaContentService
  ) {}

  setProfilesByIds<DtData, DtItem>(
    itemsIterator: (data: DtData) => DtItem[],
    profileIdIterator: (item: DtItem) => string,
    profileKey: keyof DtItem
  ): MonoTypeOperatorFunction<DtData> {
    return pipe(
      switchMap((data: DtData) => {
        let items = itemsIterator(data);
        if (items.length) {
          const profileIds = items.map(profileIdIterator);
          const uniqueProfilesIds = Array.from(new Set(profileIds));
          return this.getProfilesByIds(uniqueProfilesIds).pipe(
            map((profiles) => {
              items = items.map((item: DtItem) => {
                (item[profileKey] as DtProfileShort) = profiles.find((profile) => {
                  return profile.profileId === profileIdIterator(item);
                });
                return item;
              });
              return data;
            })
          );
        }
        return of(data);
      })
    );
  }

  getProfilesByIds(profilesIds: string[]): Observable<DtProfileShort[]> {
    return this.http
      .get<{profileDetails: DtProfileShort<string[]>[]}>(`${this.environment.API_URL}/profiles/profile/user/bulk`, {
        params: {profileIds: [...profilesIds], loadAvatars: 'ALL'}
      })
      .pipe(
        map((response) => {
          const newProfiles: DtProfileShort[] = [];
          const profiles = response.profileDetails;
          profiles.forEach((profile) => {
            newProfiles.push(this.convertProfileShortAvatars(profile));
          });
          return newProfiles;
        })
      );
  }

  convertProfileShortAvatars(profileShort: DtProfileShort<string[]>): DtProfileShort {
    const newProfile: DtProfileShort = {...profileShort, avatar: null};
    const avatars = profileShort.avatar.map((avatar) => {
      return this.mediaContentService.getMediaByUrl(avatar);
    });
    const [primaryAvatar] = avatars;
    newProfile.avatar = primaryAvatar;
    newProfile.avatars = avatars;
    return newProfile;
  }
}
